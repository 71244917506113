@keyframes growWidth {
  0% {
    width:0%;
  }
  100% {
    width:calc(100% - 22px)
  }
}

.landing footer {
  background:transparent;
}

.dialer-wrapper {
  padding: 5vh 0;
  min-height: 475px;  
  display:flex;
  flex-wrap:wrap;

  @media(max-width:$screen-md-min) {
    padding:15px 0;
  }

}

.dialer-items {
  display:flex;
  flex-direction:column;

  &:after, &:before {
    display:none;
  }

  &:hover {

    .heading {

      strong {
        background:#fff;
        border-radius:$border-radius-base;
        color:$body-bg;        
      }
      .arrow {
        border-color:rgba(255,255,255,.65);

        &:after {
          background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8.79782" height="16.13768" viewBox="0 0 8.79782 16.13768"><title>arrow</title><polyline points="0.728 0.685 7.798 8.198 0.97 15.452" fill="none" stroke="rgba(255,255,255,.65)" stroke-linejoin="round" stroke-width="2"/></svg>');
        }
      }
    }
    .well {
      background-color:$well-bg;
      @include box-shadow(0 0 30px rgba(0,0,0,.3));
    }
  }

  &:first-child {
    .heading {
      .arrow {
        animation-delay:1s;
      }
    }
  }

  &:nth-child(2) {
    .heading {
      .arrow {
        animation-delay:1.5s;
      }
    }
  }

  &:nth-child(3) {
    .heading {
      .arrow {
        animation-delay:2s;
      }
    }
  }

  .heading {

    strong {
      transition:all .15s linear;
      background-color:$body-bg;
      position:relative;
      z-index:1;
      display:inline-block;
      margin-right:10px;
      padding:5px 10px;
      border-radius:0;

    }

    .arrow {
      transition:all .15s linear;
      animation: .5s ease-in-out growWidth;
      animation-fill-mode:forwards;
      width:0%;
      border-bottom:2px dotted $gray;
      height:9px;
      position:relative;
      z-index:0;
      margin-bottom:-16px;
      margin-left:15px;

      &:after {
        content:'';
        display:block;
        background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8.79782" height="16.13768" viewBox="0 0 8.79782 16.13768"><title>arrow</title><polyline points="0.728 0.685 7.798 8.198 0.97 15.452" fill="none" stroke="#{$gray}" stroke-linejoin="round" stroke-width="2"/></svg>');
        background-repeat:no-repeat;
        position:absolute;
        right:-11px;
        width:10px;
        height:20px;
      }
    }

  }

  .well {
    flex:1;
    display:flex;
    justify-content: space-around;
    text-align:center;

    background-color:rgba(55,55,55,0);
    @include box-shadow(0 0 0 rgba(0,0,0,.3));    
    transition:all .25s linear;

    @media(max-width:$screen-md-min) {
      flex-wrap:wrap;

      .dialer-link {
        flex-grow:auto;
      }
    }

    .dialer-link {
      display:inline-block;
      text-decoration:none;
      padding:15px 15px 30px 15px;
      transition:opacity .25s ease-in;
      flex-grow:1;

      & * {
        transition:opacity .5s ease-in-out;
      }

      .dialer-image {
        position:relative;
        margin:0 auto;
        max-width:250px;
        max-height:250px;

        img {
          width:100%;
          height:auto;
        }
        .blur {
          opacity:0;
          filter: blur(15px);
          position:absolute;
          top:0;
          left:0;
        }
      }
      h2 {
        margin-top:0;
        font-size:22px;
      }
      p {
        font-size:14px;
        color:$gray-light;
        display:inline-block;
        max-width:260px;
      }
      .btn {
        opacity:0;
        display:block;
        margin:10px auto;
      }

      &:hover {
        //background:rgba(0,0,0,.15);
        .btn, .blur {
          opacity:1;
        }
        h2, p {
          color:#fff;
        }
      }
    }
  }
}

.heading {
  font-size:14px;
  padding:15px 0;
  display:inline-block;
  font-family:$font-family-monospace;
  text-transform:uppercase;
  letter-spacing:0.1em;
  font-weight:normal;


  &.process {
    background:#fff;
    border-radius:$border-radius-base;
    padding:5px 10px;
    margin-bottom:60px;

    //For lack of a cleverer way...
    &.first {
      color:#1596a9;

      &:before {
        display:none;
      }
    }
    &.second {
      color:#42ba9c;
    }
    &.third {
      color:#59be80;
    }

  }
}

.about {
  color:#fff;
  padding-top:60px;
  padding-bottom:90px;
  margin-bottom:-68px;
  background-image: url('../images/labs-bg.png'), linear-gradient(230deg, $brand-info, $brand-primary);
  background-size: 100% auto, 100% 100%;
  background-blend-mode: luminosity, normal;
  background-repeat:no-repeat, no-repeat;

  /*animation: gradientScroll 59s ease infinite;

  @keyframes gradientScroll { 
      0%{background-position:0% 0%, 0% 87%}
      50%{background-position:0% 0%, 100% 14%}
      100%{background-position:0% 0%, 0% 87%}
  }*/

  hr.short {
    border-top:3px solid #fff;
    width:100px;
    margin-left:auto;
    margin-right:auto;
  }

  p {
    font-size:18px;
    color:rgba(255,255,255,.75)
  }

  h1 {
    text-align:center;
    font-size:60px;
    line-height:70px;
    font-weight:100;
    margin-top:calc(15px + 5vh);

    @media(max-width:$screen-md-min) {
      font-size:calc(32px + 2vw);
      line-height:calc(42px + 2vw);
      margin:calc(15px + 2vh) 0;
    }
  }

  .about-group {
    padding-top:calc(15px + 3vh);
    padding-bottom:calc(15px + 6vh);
    text-align:center;
    position:relative;

    &:before {
      content: '';
      display: block;
      border-right: 2px dotted rgba(255,255,255,.65);
      height: 100%;
      width: 0;
      position: absolute;
      top: calc(47px + 3vh);
      left: 50%;

      @media(max-width:$screen-md-min) {
        display:none;
      }
    }
  }

  .about-item {
    display: flex;
    align-items: center;
    justify-content:center;
    flex-wrap:wrap;
    text-align:left;
    transition:all .25s linear;

    &:after, &:before {
      display:none;
    }  

    &.scrolled-to {
      .about-info {
        animation-fill-mode: both;
        opacity:1;

        .icon {
          margin-right:10px;
        }

        &:first-child {
          animation: fadeInLeft .5s;
        }
        &:last-child {
          animation: fadeInRight .5s;
        }
      }
    }

    @media(max-width:$screen-md-min) {
      padding:calc(15px + 2vh) 0;

      .image-link {
        margin-bottom:15px;
      }
      &.about-image-right {
        flex-direction:column-reverse;
      }
    }

    .about-info {
      opacity:0;
      padding-left:60px;

      @media(max-width:$screen-md-min) {
        padding-left:15px;
      }

      h2 {
        font-size:30px;
        line-height:1.2em;
      }
    }

    .about-image {

      &:hover {
        .image-link {
          transform:scale(1.05)
        }
      }
      .image-link {
        transition:transform .25s linear;
        display:flex;
        align-items:center;
        justify-content:center;
        position:relative;
        img {
          border-radius:$border-radius-large;
          max-width:100%;
        }
      }

    }
    .links {
      margin:30px 0 0 0;
      padding:0;
      list-style-type:none;

      li {
        display:inline-block;
        padding:5px 10px 0 10px;
        border-left:1px solid rgba(255,255,255,.25);

        &:first-child {
          border:none;
          padding-left:0;
        }

        a {
          display:inline-block;
          transition:all .1s linear;
          font-size:13px;
          font-weight:bold;
          color:#fff;
          text-decoration:none;
          border-bottom:0px solid #fff;
          padding-bottom:5px;

          &:hover {
            border-bottom:3px solid #fff;
            padding-bottom:2px;
          }
        }
      }

      @media(max-width:$screen-md-min) {
        li {
          display:block;
          border:none;
          padding-left:0;
        }
      }
    }
  }
}